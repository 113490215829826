@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

* {
    scroll-behavior: smooth;
}

input,
select {
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.no-hover:hover {
    background-color: inherit !important;
    color: inherit !important;
}

.dropdown-content.sub-menu {
    width: auto;
    max-height: 300px;
    overflow-y: auto;
    z-index: 100;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.dropdown-content.sub-menu::-webkit-scrollbar {
    display: none;
}

.dropdown-content.menu {
    z-index: 90;
}


.menu>li {
    padding: 0.5rem 1rem;
    text-align: left;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}

.sub-menu>li {
    padding: 0.5rem 1rem;
    text-align: left;
    background-color: #fff;
}

.menu>li:hover,
.sub-menu>li:hover {
    background-color: #f8f8f8;
}

.menu>li,
.sub-menu>li {
    margin: 0;
    padding: 0.25rem 1rem;
}

.navbar li {
    border-bottom: none;
    box-shadow: none;
}

.sub-menu li a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding: 0.25rem 1rem;
}

.custom-btn {
    background-color: #633b81;
    border-color: #633b81;
}

.custom-btn:hover,
.custom-btn:focus,
.custom-btn:active {
    background-color: #633b81;
    outline: none;
    border-color: #633b81;
}